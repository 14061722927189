import { Container } from '@curvo/common-ui'
import * as React from 'react'
import styled from 'styled-components'
import BackgroundBigRect from '../components/BackgroundBigReact'
import BackgroundSmallRect from '../components/BackgroundSmallRect'
import Divider from '../components/Divider'
import { FindAPartSample, FindAPartTitle } from '../components/FindAPart'
import { MAX_SMALL_SCREEN_WIDTH } from '../components/GlobalStyles'
import Layout from '../components/layout'
import Pricings from '../components/Pricings/Pricings'

/////////////////////////
// styled components
/////////////////////////
const CustomContainer = styled.div`
  position: relative;
  overflow: visible;
  margin-top: 56px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-top: 120px;
  }
`

/////////////////////////
// component classes
/////////////////////////
const FindAPartPage = () => (
  <Layout>
    <CustomContainer>
      <BackgroundSmallRect />
      <BackgroundBigRect />
      <Container flexDirection="column">
        <FindAPartTitle />
        <FindAPartSample />
        <Divider fluid />
      </Container>
      <Pricings />
    </CustomContainer>
  </Layout>
)

export default FindAPartPage
