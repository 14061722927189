import { Button, Flex, NormalText } from '@curvo/common-ui'
import * as React from 'react'
import styled from 'styled-components'
import env from '../../config/env'
import {
  MAX_MEDIUM_SCREEN_WIDTH,
  MAX_SMALL_IPAD_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
} from '../GlobalStyles'

/////////////////////////
// styled components
/////////////////////////
const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 120px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 64px;
    align-items: center;
  }
`
const TitleText = styled(NormalText)`
  margin-bottom: 34px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 20px;
  }
`
const BodyText = styled(NormalText)`
  max-width: 920px;
  margin-bottom: 40px;
  text-align: left;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    max-width: 746px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    max-width: 622px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    max-width: 100%;
    text-align: center;
  }
`

/////////////////////////
// component classes
/////////////////////////
const FindAPartTitle: React.SFC = () => (
  <Container>
    <TitleText className="hero-title">Find a Part</TitleText>
    <BodyText secondary>
      The Find-a-part service was developed in conjunction with the newsletter. We found that it was
      impossible to publish all of the parts from manufacturers and the price changes, so we moved
      it online about 10 years ago. The service now accesses a database of about 450,000 parts and
      reports list price history, selling price history, classification, and occasionally pictures
      and illustrations. The database is updated at least once a month from manufacturer price
      lists, user requests for adding new parts, and sources. Access is provided by part number,
      product line, or advanced search.
    </BodyText>
    <a href={env.WEBAPP_LINK}>
      <Button primary large>
        Go to Application
      </Button>
    </a>
  </Container>
)

export default FindAPartTitle
