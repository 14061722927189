import { Colors, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import partPreviewPic from '../../images/FindAPart/gic17.jpg'
import partPreviewPic2 from '../../images/FindAPart/gic53_t1_Cancellous.jpg'
import {
  MAX_MEDIUM_SCREEN_WIDTH,
  MAX_SMALL_IPAD_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
} from '../GlobalStyles'
import PartTabSample, { PartTabData } from './PartTabSample'

/////////////////////////
// data
/////////////////////////
const PartSamplesData: PartTabData[] = [
  {
    name: 'ExploR implant stem 8x28mm',
    manufacturer: 'Zimmer Biomet',
    price: 2595.95,
    msrp: 3069.0,
    priceChangePercent: -15,
    manufactureYear: [2017, 2016, 2013],
    previewPic: partPreviewPic,
  },
  {
    name: 'Cortex screw 3.5x16mm ST',
    manufacturer: 'DePuy Synthes',
    price: 22.95,
    msrp: 39,
    priceChangePercent: -43,
    manufactureYear: [2017, 2016, 2013],
    previewPic: partPreviewPic2,
  },
]

/////////////////////////
// styled components
/////////////////////////
const Title = styled(NormalText)`
  border-left: 4px ${Colors.Primary} solid;
  align-items: baseline;

  padding: 2px 16px;
  margin: 0 auto 42px auto;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin: 0 auto 26px auto;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding: 2px 12px;
    margin: 0 auto 26px auto;
    align-self: center;
    text-align: center;
  }
`
const SubTitle = styled(NormalText)`
  max-width: 620px;
  margin: 0 auto 56px auto;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin: 0 auto 50px auto;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    max-width: 100%;
    margin: 0 auto 56px auto;
  }
`
const CustomRow = styled(Flex)`
  width: 100%;
  margin-right: -30px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-right: -24px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    margin-right: 0;
    margin-bottom: -24px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-right: 0;
    margin-bottom: -24px;
  }
`
const PartContainer = styled.div`
  flex: 1;
  margin-right: 30px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-right: 24px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    margin-right: 0;
    margin-bottom: 24px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-right: 0;
    margin-bottom: 24px;
  }
`
const BottomPadding = styled.div`
  visibility: hidden;
  height: 120px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    height: 72px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    height: 56px;
  }
`
/////////////////////////
// component classes
/////////////////////////
const FindAPartSample: React.SFC = () => (
  <div>
    <Flex flexDirection="column" alignItems="center">
      <Title className="section-title">Find A Part Samples</Title>
      <SubTitle secondary center>
        -
      </SubTitle>
    </Flex>
    <CustomRow>
      {PartSamplesData &&
        !!PartSamplesData.length &&
        PartSamplesData.map((sampleData, index) => (
          <PartContainer>
            <PartTabSample key={'part-sample' + index} data={sampleData} id={index} />
          </PartContainer>
        ))}
    </CustomRow>
    <BottomPadding />
  </div>
)

export default FindAPartSample
