import { Flex, MarginProps, NormalText } from '@curvo/common-ui'
import * as React from 'react'
import styled from 'styled-components'
import greenUpArrow from '../../images/Arrow/Green-up.svg'
import redDownArrow from '../../images/Arrow/Red-down.svg'
import {
  MAX_MEDIUM_SCREEN_WIDTH,
  MAX_SMALL_IPAD_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
} from '../GlobalStyles'

/////////////////////////
// styled components
/////////////////////////
const Container = styled(Flex)<{ index: number }>`
  align-items: center;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 32px 32px 32px 60px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    padding: 20px 26px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    padding: 20px 26px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding: 24px 22px;
    flex-direction: column;
    display: ${props => (props.index > 0 ? 'none' : 'flex')};
  }
`
const ImgContainer = styled.img`
  max-width: 170px;
  margin-right: 16px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    max-width: 140px;
    margin-right: 12px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #a8b2bd;
  }
`
const TitleContainer = styled(Flex)`
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid #a8b2bd;
  margin-bottom: 12px;
`
const IconContainer = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 0.25em;
`
const CrossedText = styled(NormalText)`
  text-decoration: line-through;
`

/////////////////////////
// component classes
/////////////////////////
export interface PartTabData {
  name: string
  manufacturer: string
  price: number
  msrp: number
  priceChangePercent: number
  manufactureYear: number[]
  previewPic: string
}

type Props = MarginProps & {
  id: number
  data: PartTabData
}

function drawPriceDiff(priceChangePercent: number) {
  if (priceChangePercent === 0) {
    return null
  } else if (priceChangePercent < 0) {
    return (
      <Flex alignItems="center">
        <IconContainer src={redDownArrow} />
        <NormalText red size="1.25em">
          {priceChangePercent}%
        </NormalText>
      </Flex>
    )
  } else {
    return (
      <Flex alignItems="center">
        <IconContainer src={greenUpArrow} />
        <NormalText green size="1.25em">
          {priceChangePercent}%
        </NormalText>
      </Flex>
    )
  }
}

function drawManufactureYears(years: number[]) {
  const maxYear = Math.max(...years)
  const minYear = Math.min(...years)

  type manuYear = {
    value: number
    avai: boolean
  }

  const list: manuYear[] = []
  for (let i = maxYear; i >= minYear; i--) {
    list.push({
      value: i,
      avai: years.indexOf(i) >= 0,
    })
  }

  return list.map((year, index) =>
    year.avai ? (
      <NormalText key={'manu-year' + index} secondary marginRight="1em">
        {year.value}
      </NormalText>
    ) : (
      <CrossedText key={'manu-year' + index} secondary marginRight="1em">
        {year.value}
      </CrossedText>
    ),
  )
}

const PartTabSample: React.SFC<Props> = props => {
  const { data, id } = props
  return (
    <Container
      index={id}
      margin={props.margin}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
    >
      <ImgContainer src={data.previewPic} />
      {/* Title */}
      <Flex flexDirection="column" style={{ width: '100%' }}>
        <NormalText className="small-title">{data.name}</NormalText>
        <TitleContainer>
          <NormalText secondary marginRight="0.25em">
            by
          </NormalText>
          <NormalText primary>{data.manufacturer}</NormalText>
        </TitleContainer>
        {/* Price */}
        <Flex justifyContent="space-between">
          <Flex alignItems="flex-start">
            <NormalText className="hero-title" marginRight="0.25em">
              ${Math.floor(data.price)}
            </NormalText>
            <NormalText secondary marginRight="10px">
              {Math.round((data.price % 1) * 100)}
            </NormalText>
            {drawPriceDiff(data.priceChangePercent)}
          </Flex>
          <Flex flexDirection="column">
            <NormalText secondary>${data.msrp}</NormalText>
            <NormalText secondary>List Price</NormalText>
          </Flex>
        </Flex>
        {/* Year */}
        <Flex flexWrap="wrap">{drawManufactureYears(data.manufactureYear)}</Flex>
      </Flex>
    </Container>
  )
}
export default PartTabSample
