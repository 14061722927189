import { StripePlanTier, StripePlanType } from '@curvo/apollo'
import { Button, Colors, Flex } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import configs from '../../config/env'

const defaultProps = {
  recommneded: false,
  selected: false,
  loading: false,
}

export type PlanProps = {
  title?: string
  data: StripePlanType
  recommneded?: boolean
  loading?: boolean
  spacing?: string
}

type Props = Partial<typeof defaultProps> & PlanProps & {}

const initialState = {}

type State = Readonly<typeof initialState>

type StyledProps = {
  isRecommended?: boolean
  spacing?: string
}

export const Container = styled.div<StyledProps>`
  flex: 1;
  margin-right: ${props => (props.spacing ? props.spacing : '1rem')};

  position: relative;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: ${props =>
    props.isRecommended ? 'linear-gradient(-180deg, #0076FD 0%, #054FDA 100%)' : 'white'};
`

export const Header = styled(Flex)<StyledProps>`
  border-bottom: 1px solid
    ${props => (props.isRecommended ? 'rgba(255, 255, 255, 0.15)' : 'rgba(155, 155, 155, 0.15)')};
  padding: 1.875rem;
  & > span {
    color: ${props => (props.isRecommended ? 'white' : Colors.Dark)};
    font-size: 1.5rem;
    position: relative;
    padding-left: 1.5rem;
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background: ${props =>
        props.isRecommended ? 'white' : 'linear-gradient(-180deg, #0076FD 0%, #054FDA 100%)'};
      width: 4px;
      height: 100%;
    }
  }
`

export const ContentPadding = 1.875
export const Content = styled(Flex)`
  flex-direction: column;
  padding: ${ContentPadding}rem;
  flex: 1;
`

export const PriceTag = styled(Flex)<StyledProps>`
  font-size: 2.5rem;
  align-items: baseline;
  color: ${props => (props.isRecommended ? 'white' : 'black')};
  & > span {
    margin-left: 0.5rem;
    color: ${props => (props.isRecommended ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)')};
  }
`

export const TierPrice = styled(Flex)<StyledProps>`
  margin-bottom: 0.5rem;
  color: ${props => (props.isRecommended ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)')};
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const Text = styled(Flex)<StyledProps>`
  font-size: 1rem;
  color: ${props => (props.isRecommended ? 'white' : '#9b9b9b')};
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const DescriptionContainer = styled(Flex)`
  margin-bottom: ${2.75 + 2.25}rem;
  flex-direction: column;
  flex: 1;
`

export const StyledAnchorLink = styled.a`
  position: absolute;
  bottom: ${ContentPadding}rem;
  width: 100%;
  padding-right: ${ContentPadding * 2}rem;
`

export type GetTiers = {
  tier1st: StripePlanTier
  otherTiers: StripePlanTier[]
}

const Description = styled('div')`
  ul {
    list-style: none;
    li {
      margin-bottom: 0.5rem;
    }
  }
`

export default class Plan extends React.PureComponent<Props, State> {
  static defaultProps = defaultProps

  readonly state: State = initialState

  // private formatPrice = (price?: number | null) => {
  //   if (!price) {
  //     return 'N/A'
  //   }
  //   return (price / 100).toFixed(2)
  // }

  // private getBillInterval = (interval: BillingIntervalEnum) => {
  //   switch (interval) {
  //     case BillingIntervalEnum.Month:
  //       return 'month'
  //     case BillingIntervalEnum.Week:
  //       return 'unit'
  //     default:
  //       return 'year'
  //   }
  // }

  // private getTiers = (tiers: StripePlanTier[]) =>
  //   tiers.reduce(
  //     (previousResult: GetTiers, currentItem: StripePlanTier) => {
  //       if (currentItem.unitAmount === 0) {
  //         return {
  //           ...previousResult,
  //           tier1st: currentItem,
  //         }
  //       }
  //       return {
  //         ...previousResult,
  //         otherTiers: [...previousResult.otherTiers, currentItem],
  //       }
  //     },
  //     {
  //       tier1st: {},
  //       otherTiers: new Array(),
  //     },
  //   )

  // private renderOtherTiers = (
  //   { tier1st, otherTiers }: GetTiers,
  //   isRecommended: boolean = false,
  // ) => {
  //   return (
  //     <React.Fragment>
  //       <TierPrice isRecommended={isRecommended}>
  //         Up to {tier1st.upTo} queries
  //       </TierPrice>
  //       {otherTiers.map((tier, tierIndex) => (
  //         <TierPrice
  //           key={`tier-price-${tierIndex}`}
  //           isRecommended={isRecommended}
  //         >{`$${this.formatPrice(
  //           tier.unitAmount,
  //         )} / query afterward `}</TierPrice>
  //       ))}
  //     </React.Fragment>
  //   )
  // }

  render() {
    const { data, recommneded, title, selected, loading, spacing } = this.props
    // const tiers = data.tiers ? getTiers(data.tiers) : undefined
    // const price = tiers
    //   ? formatPrice(tiers.tier1st.flatAmount)
    //   : formatPrice(data.amount)
    if (!data) {
      return <div />
    }
    // const onClickSubcribe = () => this.handleSubscribe(data.id)

    return (
      <Container isRecommended={recommneded} spacing={spacing}>
        <Header isRecommended={recommneded}>
          <span>{title || data.nickname}</span>
        </Header>
        <Content>
          <PriceTag isRecommended={recommneded} marginBottom="2rem">
            $
            {(data.metadata && data.metadata.price && data.metadata.price.toLocaleString()) ||
              (data.amount && data.amount.toLocaleString())}{' '}
            <span>/ year</span>
          </PriceTag>
          {/* {tiers && (
            <Flex marginTop="0.5rem" marginBottom="1rem" flexDirection="column">
              {tiers.otherTiers && renderOtherTiers(tiers, recommneded)}
            </Flex>
          )} */}
          <DescriptionContainer>
            <Description dangerouslySetInnerHTML={{ __html: data.metadata.description }} />
          </DescriptionContainer>

          <StyledAnchorLink href={configs.WEBAPP_LINK + 'auth/signup'}>
            <Button
              light={!selected}
              primary={selected}
              disabled={loading}
              loading={loading && selected}
              block
              large
              style={{
                boxShadow: '0 1px 4px 0 rgba(0,0,0,0.10)',
                minWidth: '180px',
              }}
            >
              Subscribe Now
            </Button>
          </StyledAnchorLink>
        </Content>
      </Container>
    )
  }
}
