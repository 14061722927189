import { StripeProductEnum, StripeProductType, SubscriptionProductsData } from '@curvo/apollo'
import { Container, Flex, NormalText } from '@curvo/common-ui'
import LoadingIndicator from '@curvo/common-ui/dist/components/Loading/Spinner'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import styled from 'styled-components'
import Client from '../../config/Apollo'
import { MAX_MEDIUM_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../GlobalStyles'
import Plan from './Plan'
import PlanStatic from './PlanStatic'

const NewsHeader = styled(Flex)`
  flex-direction: column;
  align-items: center;

  margin-top: 120px;
  margin-bottom: 42px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-top: 72px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-top: 48px;
  }

  div {
    max-width: 620px;
  }
`

const HeaderTitle = styled(NormalText)`
  margin-bottom: 32px;
  margin: '0 auto 32px auto';
  border-left: #0076fd 3px solid;

  padding-left: 20px;
  margin-bottom: 42px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 26px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding-left: 16px;
    margin-bottom: 26px;
  }
`

export const PlansContainer = styled(Flex)`
  margin-bottom: 116px;
  align-content: stretch;
  flex-wrap: wrap;

  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 72px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 56px;
  }
`

const Pricings: React.FC = () => {
  const getProductByType = (data: StripeProductType[], type: StripeProductEnum) =>
    data.find(item => item.type === type && item.active === true)

  return (
    <Container flexDirection="column">
      <NewsHeader>
        <HeaderTitle className="section-title">Pricings</HeaderTitle>
        <NormalText secondary center>
          An Orthopedic Networks News by Curvo subscription gives you access to the gold standard in orthopedic content,
          classification, and categorization. We have laid out the pricing by subscription type so you can sign up for
          the one that best fits your business needs. Choose from a Newsletter only subscription, a Find-A-Part only
          subscription, or both.If you are looking to add multiple users, contact us for enterprise pricing.
        </NormalText>
      </NewsHeader>
      <PlansContainer>
        <ApolloProvider client={Client.getClient()}>
          <SubscriptionProductsData>
            {({ data, loading }) => {
              if (!data || loading) {
                return <LoadingIndicator center />
              }

              const newsletter = getProductByType(data.subscriptionProducts.data, StripeProductEnum.Newsletter)
              const findAPart = getProductByType(data.subscriptionProducts.data, StripeProductEnum.FindAPart)

              const newsletterPlans =
                newsletter && newsletter.plans
                  ? newsletter.plans.filter(item => item.id.includes('public-') && item.active === true)
                  : []

              let findAPartPlans =
                findAPart && findAPart.plans
                  ? findAPart.plans.filter(item => item.id.includes('public-') && item.active === true)
                  : []

              if (findAPartPlans.length) {
                findAPartPlans = [findAPartPlans[0]]
              }
              const plansList = [...newsletterPlans, ...findAPartPlans]

              return (
                <React.Fragment>
                  {plansList.map(plan => (
                    <React.Fragment key={`news-${plan.id}`}>
                      <Plan data={plan} spacing={'1.5rem'} />
                    </React.Fragment>
                  ))}
                  <PlanStatic spacing={'0rem'} />
                </React.Fragment>
              )
            }}
          </SubscriptionProductsData>
        </ApolloProvider>
      </PlansContainer>
    </Container>
  )
}

export default Pricings
